
.workflow-progress-bar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.workflow-progress-bar::before {
  content: "";
}

.workflow-progress-bar::after {
  content: "";
}

.workflow-progress-bar-circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1.5rem;

    background-color: #AAAAAA;
    position: relative;
    /*z-index: var(--z-one);*/
    cursor: pointer;

    margin: auto;

    display: flex;
    justify-content: center;
    align-items: center;
}

.workflow-progress-bar-item:hover .workflow-progress-bar-circle {
    background-color: #888888;
}

.workflow-progress-bar-item.active .workflow-progress-bar-circle {
    background-color: var(--bs-primary);
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
}

.workflow-progress-bar-strike {
    width: 100%;
    background-color: #dddddd;
    border-radius: 5px;
    height: 0.25rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: .875rem; /* .75 + .25/2 */
    margin: auto;

    /*z-index: var(--z-default);*/
}

[data-bs-theme=dark] .workflow-progress-bar-strike {
    background-color: #333333;
}

.workflow-progress-bar-item .workflow-progress-bar-label {
    /*color: transparent;*/

    font-size: 0.9rem;
    margin-bottom: 0.25rem;
    white-space: nowrap;

    position: absolute;
    top: 1.8rem;
    transform: translateX(-50%) translateX(.75rem);;
}

.workflow-progress-bar-item.active .workflow-progress-bar-label {
    transform: translateX(-50%) translateX(1rem);;
}

.progress-circle-icon {
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.8);
}

.workflow-progress-bar-item.active .progress-circle-icon {
    font-size: 1rem;
    color: rgba(255, 255, 255, 1.0);
}

.workflow-progress-bar-status-circle {
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;

    position: absolute;
    top: -0.33rem;
    right: -0.33rem;

    color: #ffffff;
    font-size: 0.7rem;
    font-weight: 700;

    display: flex;
    justify-content: center;
    align-items: center;

    color: rgba(255, 255, 255, 0.8);
}

.workflow-progress-bar-status-circle.left {
    left: -0.33rem;
}

.workflow-progress-bar-status-circle.tasks-remaining {
    background-color: var(--bs-primary);
}

.workflow-progress-bar-status-circle.flags {
    background-color: #d9534f;
}

.workflow-progress-bar-status-circle.completed {
    background-color: #5cb85c;
}

.container.project-footer-progress-container {
    padding: 0;
}