.workflow-declaration-text {
    background-color: #EEEEEE;
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
}

.declaration-drafter-heading {
    display: flex;
    min-height: 48px !important;
    overflow: hidden !important;
    align-items: center;
    justify-content: center;
    padding: 0 2.5vw !important;
    border-bottom: 1px solid #cccccc;
}

.declaration-drafter-heading h5 {
    margin: 0;
}

.declaration-drafter-heading .btn {
    height: fit-content;
    margin-left: 1rem;
}

.declaration-drafter-heading .btn:first-of-type {
    margin-left: auto;
}

.declaration-drafter-section.fullscreen {
    background-color: rgba(255, 255, 255, 1.0) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    flex-direction: column;
    padding: 0 0;
    max-height: 100vh;
    max-width: 100vw;
}

.declaration-drafter-section.fullscreen > div {
    width: 100vw;
    height: fit-content;
    overflow-y: scroll;
    padding: 6px 0;
}

.drafting-notes.col {
    padding-left: 2.5vw;
    overflow-y: scroll;
    height: calc(100vh - 96px);
}

.drafting-fields.col {
    padding-right: 2.5vw;
    overflow-y: scroll;
    height: calc(100vh - 96px);
}

.drafting-main.row {
    overflow-y: hidden !important;
    height: calc(100vh - 96px);
    padding: 0;
}