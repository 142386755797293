
.App {
    text-align: center;
    padding: 0 !important;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    min-height: 760px;
    height: 100vh;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

html {
    overflow-x: auto;
    overflow-y: hidden;
    min-width: 864px;

}

body {
    min-height: 100vh;
}

.navbar .container {
    margin-left: 0;
    margin-right: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100vw;
    max-width: 100vw;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

