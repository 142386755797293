.insights-container .row {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.insights-container {
    overflow-y: scroll;
}

.insights-container .recharts-wrapper {
    margin-left: auto;
    margin-right: auto;
}

.person-stats {
    text-align: left;
    border-top: 1px solid #cccccc;
}

.insights-chart {
    text-align: center;
}