.doc-pdf-view {
    height: 100%;
}

.doc-text-view {
    white-space: pre-line;
    text-align: left;
}

.sandbox-header {
    height: 60px;
    display: flex;
    align-items: center;
}

.sandbox-header select,
.sandbox-header div {
    margin-right: 1rem;
}

.sandbox-contents-row {
    max-height: calc(100vh - 120px);
}

.sandbox-contents-row .col {
    max-height: calc(100vh - 120px);
    overflow-y: scroll;
    padding: 0;
}

.doc-chunk {
    background-color: rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
}

.doc-chunk.parent-chunk {
    padding: 1rem;
}

.sandbox-header select {
    width: fit-content;
}

.doc-chunk .badge {
    text-wrap: pretty;
    text-align: left;
}