
.declaration-form {
    /*max-width: 33vw;*/
    padding: 1rem 7.5vw;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.workflow-declaration-form .dynamic-field,
.declaration-form .dynamic-field {
    padding-top: 1rem;
    font-size: 0.9rem;
}

.declaration-form .dynamic-field .form-control {
    font-size: 0.9rem !important;
}

.declaration-main-section, .declarations-contents-row {
    height: calc(100vh - 56px) !important;
}

.col.declarations-text-col {
    background-color: #eeeeee;
    text-align: left;
    padding: 1rem 2rem;
}

.declaration-main-section .col {
    height: 100%;
    overflow-y: scroll;
}

.declaration-main-section .copy-button {
    margin-bottom: 1rem;
}

.drafting-footer .btn {
    width: fit-content;
    margin: 0 0.5rem;
}

.drafting-footer.row {
    display: flex;
    justify-content: center;
    height: 48px !important;
}

.dynamic-field-prompt.slider-field {
    display: flex;
    gap: 1rem;
    text-align: center;
    align-items: center;
}

.declaration-interview-question {
    margin-bottom: 1rem;
}

.drafting-main {
    /*max-height: 80vh;*/
}

.drafting-footer {
    border-top: 1px solid #cccccc;
 }