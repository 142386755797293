.login-logo {
    width: 150px;
}

.login-page-col {
    width: 472px !important;
}

.login-card {
    border-radius:0.5rem !important;
}

.login-card .form-label {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.8rem;
    color: #35383E;
}

.login-card .login-button, .login-button.wide {
    width: 100%;
    padding: 16px;
    font-weight: 500;
}

.login-card .login-reset-link {
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
}
