.table-view-container {
    margin-top: 2rem;
}

.table-view-container .table-view-header,
.client-info .client-info-header {
    display: flex;
    margin-bottom: 0.5rem;
}

.table-view-container .table-view-header .dropdown {
    margin-left: auto;
}


.table-view-container table .clients-rap-cell {
    /*display: flex;*/
}

.table-view-container table td {
    vertical-align: middle;
}

.clients-main-section {
    overflow-y: scroll;
}

.client-info {
    text-align: left;
}

.client-info .client-info-header .client-name {
    margin-right: auto;
}

.embed-pdf {
    width: 100%;
}

.client-rap-view {
    text-align: left;
}

.rap-view-row.row {
    max-height: 75vh !important;
}

.rap-view-row iframe,
.rap-view-row .pretty-json-container.object-container {
    max-height: 75vh !important;
    overflow-x: scroll;
}

.client-workflows {
    text-align: left;
    margin-top: 3rem;
}

.workflow-card {
    width: 18rem;
    min-height: 12rem;
}

.client-workflows .workflows-container {
    display: flex;
    gap: 1rem;
}

