.workflow-card .card-body {
    cursor: pointer;
}

.workflow-card.add-card .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 500;
}

.workflow-card .card-body:hover {
    background-color: #eeeeee;
}

.workflow-card.add-card .card-body .fa-plus {
    font-size: 3rem;
}


.workflow-title {
    text-align: left;
    background-color: var(--bs-success);
    color: #ffffff;
    margin: 0;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    font-weight: 600;
}


.workflow-container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 182px);
}

.workflow-steps {
    padding: 1rem 1rem calc(1rem + 16px) 1rem;
    border-bottom: 1px solid #aaaaaa;
    height: 80px;
}

.workflow-container .workflow-instance-list {
    border-right: 1px solid #aaaaaa;
    width: 20vw;
    overflow-y: scroll;
}

.workflow-container .workflow-step-workspace {
    width: 80vw;
}

.workflow-container .workflow-step-workspace.full-width {
    width: 100vw !important;
}

.workflow-instance-list .workflow-instance-list-item {
    padding: 0.25rem 1rem;
    text-align: left;
    font-weight: 500;
    color: #000000;
    border-bottom: 1px solid #cccccc;
    display: flex;
    align-items: center;
}

.workflow-instance-list .workflow-instance-list-item.active {
    background-color: #cccccc;
    /*color: #ffffff;*/
    font-weight: 700;
}

.workflow-instance-list .workflow-instance-list-item .svg-inline--fa:first-of-type {
    margin-left: auto;
}

.workflow-instance-list .workflow-instance-list-item .svg-inline--fa {
    cursor: pointer;
    margin-left: 1rem;
}

.workflow-container .workflow-step-workspace {
    display: flex;
    flex-direction: column;
    background-color: #f8f9fb;
}

.workflow-step-workspace .workflow-step-controls {
    margin-top: auto;
    padding: 0.5rem;
    width: 100%;
    border-top: 1px solid #cccccc;
    display: flex;
    gap: 1rem;
    background-color: #ffffff;
}

.workflow-step-workspace .workflow-step-controls .show-rap-btn {
    margin-left: auto;
}

.workflow-step-workspace .workflow-step-controls .revert-btn {
    margin-right: auto;
}

.workflow-step-workspace .step-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 0;
}

.step-container .step-main-section {
    flex: 1;
    padding: 2rem;
    text-align: left;

    max-width: 1048px !important;
    margin-right: auto;

    overflow-y: scroll;

    max-height: calc(100vh - 56px - 80px - 46px - 56px);

}

.step-container .workflow-checkist-bar {
    border-left: 1px solid #aaaaaa;
    width: 20vw;
    text-align: left;
    max-height: calc(100vh - 56px - 80px - 46px - 56px);
    background-color: #ffffff;
}

.workflow-checkist-bar .step-checklist-item {
    margin-bottom: 0.25rem;
}

.workflow-checkist-bar .workflow-checklist {
    padding: 1rem;

    height: 50%;
    overflow-y: scroll;
}

.workflow-checkist-bar .workflow-history {
    height: 40vh;
    border-bottom: 1px solid #aaaaaa;
    padding: 1rem;
    overflow-y: scroll;
}

.workflow-history .history-item {
    margin-bottom: 0.5rem;
    border: 1px solid #aaaaaa;
    border-radius: 5px;
    padding: 0.5rem;
    background-color: var(--bs-gray-100);
}

.history-item .history-item-header {
    display: flex;
    gap: 1rem;
}

.history-item .history-timestamp {
    font-weight: 500;
    text-wrap: nowrap;
}

.history-item .history-agent {
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.history-item .history-message {
    font-weight: 700;

}

.rap-overlay {
    position: fixed;
    left: 0;
    bottom: 56px;
    height: calc(100vh - 56px - 80px - 46px - 56px);
    background-color: white;
    min-width: 40px;
    width: 40vw;
    border-left: 1px solid #aaaaaa;
    text-align: left;
}

.rap-overlay.left1 {
    left: 40vw;
}

.rap-overlay .rap-overlay-contents {
    height: 75vh;
    min-width: 40px;
}

.rap-overlay-contents .client-rap-view {
    height: calc(100% - 24px);
}

.rap-overlay-contents .rap-view-row {
    height: 100%;
}

.count {
    margin-bottom: 0.5rem;
    border-top: 1px solid #aaaaaa;
}

.count .count-title {
    /*font-weight: 700;*/
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    display: flex;
}

.count.with-sentence .count-title {
    font-weight: 700;
}

.case-select-section .cases {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.count-comments .comments-title {
    font-style: italic;
}

.case-comment .case-comment-date {
    font-weight: 700;
}

.case-comment {
    display: flex;
    gap: 0.25rem
}

.count .count-sentences {
    font-weight: 700;
}

.arrest-info {
    /*font-weight: 700;*/
    font-style: italic;
    margin-bottom: 0.25rem;
}


.count-id {
    display: flex;
    gap: 0.25rem;
    font-weight: 700;
}

.count-id .count-id-label {
    font-weight: 500;
    font-style: italic;
}

.modal.case-view-modal .modal-dialog {
    min-width: 66vw;
}

.count-title .count-select-btn {
    margin-left: auto;
    height: fit-content;
}

.change-charge-btn {
    margin-left: auto;
    height: fit-content;
    margin-right: 1rem;
}

.court-contacts {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.court-contact-card .card-title {
    display: flex;
}

.court-contact-card .card-title .btn {
    margin-left: auto;
}

.modal.workflow-details-modal .modal-dialog {
    max-height: 90vh;
    max-width: 80vw;
    min-height: 50vh;
    overflow-y: hidden;
}

.modal.workflow-details-modal .modal-content {
    height: 100%;
    width: 100%;
    max-height: 90vh;
    max-width: 80vw;
    min-height: 50vh;

    overflow-y: hidden;
}

.modal.workflow-details-modal .modal-body,
.modal.workflow-details-modal .modal-body .row {
    overflow-y: hidden;
    height: 100%;
    width: 100%;
}

.modal.workflow-details-modal .modal-content .col {
    overflow-y: auto;
    max-height: 80vh;
}

.workflow-data-view .data-label {
    font-weight: 600;
}

.workflow-checkist-bar .workflow-data-view {
    height: 50%;
    border-bottom: 1px solid #aaaaaa;
    padding: 0;
    overflow-y: scroll;
}

.workflow-checkist-bar.full-data .workflow-data-view {
    height: 100%;
    border-bottom: 0;
}

.workflow-data-view .table {
    font-size: .9rem;
    margin: 0;
}

.workflow-data-view .table td {
    padding: 0.125rem 0.5rem;
}

.batch-badge {
    /*margin-left: 0.5rem;*/
}

.workflow-instance-list .workflow-instance-list-filter {
    text-align: left;
    font-weight: 600;
    padding: 0.25rem 1rem;
    border-bottom: 1px solid #666666;
    color: #666666;
    display: flex;
    align-items: center;
}

.workflow-instance-list-filter .dropdown {
    margin-left: 1rem;
}

.workflow-instance-list-filter .dropdown-toggle {
    background-color: transparent;
    color: #666666;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10rem;
}